export const useSlideHandling = () => {
  const breakpoints = useBreakpoints();
  const slideSize = computed(() => breakpoints.breakpoint.value === 'sm' ? 408 : 300);
  const gapSize = 24;

  const slidesPerPage = computed(() =>
    breakpoints.breakpoint.value
      ? ({'sm': 1, 'md': 2, 'lg': 3, 'xl': 4, '2xl': 4})[breakpoints.breakpoint.value]
      : 1
  );

  return {
    slideSize,
    gapSize,
    slidesPerPage
  }
}
